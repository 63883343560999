import gql from "graphql-tag";

const PLANT_CLASSIFICATION_IMPORTS = gql`
  query PlantClassificationImports($after: String, $before: String) {
    plantClassificationImports(after: $after, before: $before) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          currentState
        }
      }
    }
  }
`;

export default PLANT_CLASSIFICATION_IMPORTS;
