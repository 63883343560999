import gql from "graphql-tag";

const PLANT = gql`
  query Plant($id: String!) {
    plant(id: $id) {
      id
      name
      kind
      status
      journalEntriesCount

      plantClassification {
        id
        name
        family
        genus
        illustrationUrl
      }

      sensor {
        id
        temperatureRange
        moistureRange
        lightRange
        humidityRange
        firmwareVersion
        serialNumber
        ppfdCalibrationFactor

        lastReading {
          id
          createdAt
        }
      }
    }
  }
`;

export default PLANT;
