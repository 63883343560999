import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../routes";

const AppContent = () => {
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                >
                  {route.children
                    ? route.children.map((child, idx) => (
                        <Route
                          key={idx}
                          index={child.index}
                          path={child.path}
                          exact={child.exact}
                          name={child.name}
                          element={<child.element />}
                        />
                      ))
                    : null}
                </Route>
              )
            );
          })}

          <Route path="/" element={<div />} />
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
