import gql from "graphql-tag";

const JOURNAL_ENTRIES = gql`
  query ClientVersions {
    clientVersions {
      id
      build
      version
      releaseDate
    }
  }
`;

export default JOURNAL_ENTRIES;
