import gql from "graphql-tag";

const USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      fullName
      email
      isConfirmed
      hasActiveSubscription
      plantsCount
      sensorsCount
      journalEntriesCount
      discordId

      subscription {
        id
        status
        currentPeriodStart
        currentPeriodEnd
        startDate
        externalId
        customerId

        plan {
          id
        }
      }
    }
  }
`;

export default USER;
