import gql from "graphql-tag";

const JOURNAL_ENTRIES = gql`
  query JournalEntries($plantId: String!, $first: Int, $after: String) {
    journalEntries(plantId: $plantId, first: $first, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          date
        }
      }
    }
  }
`;

export default JOURNAL_ENTRIES;
