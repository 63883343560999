import gql from "graphql-tag";

const USER_PARTICIPANT_PROGRAMS = gql`
  query UserParticipantPrograms($state: String!) {
    userParticipantPrograms(state: $state) {
      id
      currentState

      participantProgram {
        id
        name
      }

      user {
        id
        email
        discordId
      }
    }
  }
`;

export default USER_PARTICIPANT_PROGRAMS;
