import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilAccountLogout } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Cookies from "js-cookie";
import { useQuery } from "@apollo/client";

import { CURRENT_USER } from "graphql/queries";

const AppHeaderDropdown = () => {
  const { data: currentUserData, client } = useQuery(CURRENT_USER, {
    fetchPolicy: "cache-only",
  });
  const handleSignOutClick = async (event) => {
    event.preventDefault();

    Cookies.remove("token");

    await client.writeQuery({
      query: CURRENT_USER,
      data: { currentUser: null },
    });

    client.clearStore();
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        {currentUserData.currentUser.fullName}
      </CDropdownToggle>

      <CDropdownMenu placement="bottom-end">
        <CDropdownItem href="#" onClick={handleSignOutClick}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Sign out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
