import gql from "graphql-tag";

const PLANT_CLASSIFICATIONS = gql`
  query PlantClassifications(
    $filter: PlantClassificationFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    plantClassifications(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          commonNames
          preferredName
        }
      }
    }
  }
`;

export default PLANT_CLASSIFICATIONS;
