import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CToaster } from "@coreui/react";

import { AuthorizedRoute, Loading } from "components";
import { ToastContext } from "contexts";

import "./theme/index.scss";

const Layout = lazy(() => import("layouts/Layout.js"));
const Login = lazy(() => import("pages/Login"));

const App = () => {
  const [toast, setToast] = useState();

  return (
    <ToastContext.Provider value={{ addToast: setToast }}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<AuthorizedRoute />}>
              <Route index path="*" name="Dashboard" element={<Layout />} />
            </Route>

            <Route exact path="/login" name="Login Page" element={<Login />} />
          </Routes>

          <CToaster push={toast} placement="top-end" />
        </Suspense>
      </BrowserRouter>
    </ToastContext.Provider>
  );
};

export default App;
