import gql from "graphql-tag";

const JOURNAL_ENTRY = gql`
  query JournalEntry($id: String!) {
    journalEntry(id: $id) {
      id
      date
      body
      healthAssessment
      imageFullsizeUrl
    }
  }
`;

export default JOURNAL_ENTRY;
