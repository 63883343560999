import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      roles
    }
  }
`;

export default CURRENT_USER;
