import React from "react";
import {
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormSwitch,
  CFormLabel,
  CFormText,
  CInputGroup,
  CButton,
} from "@coreui/react";
import { useForm, useFieldArray } from "react-hook-form";

const valueForStatus = (status) => {
  switch (status) {
    case "active":
      return 1;
    case "inactive":
      return 0;
    case "true":
    case true:
      return "active";
    case "false":
    case false:
      return "inactive";
    default:
      return 0;
  }
};

const formatHashArray = (data) =>
  data.map((step) => ({
    [step.title]: step.details,
  }));

const formatHashArrayDefaults = (data) =>
  data
    ? data.map((step) => {
        const obj = {};

        Object.keys(step).forEach((k) => {
          obj.title = k;
          obj.details = step[k];
        });

        return obj;
      })
    : [];

export default function ParticipantForm({
  children,
  participantProgram,
  onSubmit,
}) {
  const {
    register,
    control,
    handleSubmit: formSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      enrollmentSteps: formatHashArrayDefaults(
        participantProgram?.enrollmentSteps
      ),
      resources: formatHashArrayDefaults(participantProgram?.resources),
    },
  });
  const {
    fields: enrollmentStepsFields,
    append: appendEnrollmentStep,
    remove: removeEnrollmentStep,
  } = useFieldArray({
    control,
    name: "enrollmentSteps",
  });
  const {
    fields: resourcesFields,
    append: appendResource,
    remove: removeResource,
  } = useFieldArray({
    control,
    name: "resources",
  });
  const handleSubmit = (data) => {
    const status = valueForStatus(data.status);
    const enrollmentSteps = formatHashArray(data.enrollmentSteps);
    const resources = formatHashArray(data.resources);

    onSubmit({
      variables: {
        ...data,
        status,
        enrollmentSteps,
        resources,
      },
    });
  };

  console.log(resourcesFields);

  return (
    <CForm onSubmit={formSubmit(handleSubmit)}>
      {children}

      <CRow>
        <CCol>
          {participantProgram ? (
            <>
              <div className="mb-3">
                <div>
                  <strong>ID</strong>
                </div>

                <div>{participantProgram.id}</div>
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="status">
                  <strong>Active?</strong>
                </CFormLabel>

                <CFormSwitch
                  id="status"
                  defaultChecked={valueForStatus(participantProgram?.status)}
                  {...register("status")}
                />

                <CFormText>
                  Only active programs will appear in program list
                </CFormText>
              </div>
            </>
          ) : null}

          <div className="mb-3">
            <CFormLabel htmlFor="name">
              <strong>Name*</strong>
            </CFormLabel>

            <CFormInput
              placeholder="Name"
              defaultValue={participantProgram?.name}
              invalid={!!errors.name}
              {...register("name", { required: true })}
            />
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="details">
              <strong>Details*</strong>
            </CFormLabel>

            <CFormTextarea
              id="details"
              placeholder="Details"
              invalid={!!errors.details}
              defaultValue={participantProgram?.details}
              {...register("details", { required: true })}
            />

            <CFormText>"What we ask of you" in the app</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="incentives">
              <strong>Incentives*</strong>
            </CFormLabel>

            <CFormTextarea
              id="incentives"
              placeholder="Incentives"
              invalid={!!errors.incentives}
              defaultValue={participantProgram?.incentives}
              {...register("incentives", { required: true })}
            />

            <CFormText>"What you get" in the app</CFormText>
          </div>
        </CCol>

        <CCol>
          <div className="mb-3">
            <CFormLabel htmlFor="requiresApproval">
              <strong>Requires approval?</strong>
            </CFormLabel>

            <CFormSwitch
              id="requiresApproval"
              defaultChecked={participantProgram?.requiresApproval}
              {...register("requiresApproval")}
            />

            <CFormText>
              If unchecked, users will be immediately enrolled when expressing
              interest in joining
            </CFormText>
          </div>
        </CCol>
      </CRow>

      <CRow className="flex-column mb-3">
        <CCol>
          <div className="mb-3">
            <CFormLabel htmlFor="enrollmentSteps" className="mb-0">
              <strong>Enrollment steps</strong>
            </CFormLabel>
            <CFormText className="mb-1">
              "Next steps" in welcome email
            </CFormText>

            {enrollmentStepsFields.map((field, index) => (
              <CRow key={field.id} className="mb-3">
                <CCol>
                  <CInputGroup className="d-flex gap-3" key={field.id}>
                    <CFormInput
                      placeholder="Title"
                      {...register(`enrollmentSteps.${index}.title`)}
                    />

                    <CFormInput
                      placeholder="Details"
                      {...register(`enrollmentSteps.${index}.details`)}
                    />

                    {
                      <CButton
                        color="secondary"
                        onClick={() => removeEnrollmentStep(index)}
                      >
                        -
                      </CButton>
                    }
                  </CInputGroup>
                </CCol>
              </CRow>
            ))}
          </div>
        </CCol>

        <CButton
          color="secondary"
          onClick={() => appendEnrollmentStep({ details: "", title: "" })}
        >
          +
        </CButton>
      </CRow>

      <CRow className="flex-column">
        <CCol>
          <div className="mb-3">
            <CFormLabel htmlFor="resources" className="mb-0">
              <strong>Resources</strong>
            </CFormLabel>
            <CFormText className="mb-1">
              "Helpful resources" in the welcome email
            </CFormText>

            {resourcesFields.map((field, index) => (
              <CRow key={field.id} className="mb-3">
                <CCol>
                  <CInputGroup className="d-flex gap-3" key={field.id}>
                    <CFormInput
                      placeholder="Title"
                      {...register(`resources.${index}.title`)}
                    />

                    <CFormInput
                      placeholder="Details"
                      {...register(`resources.${index}.details`)}
                    />

                    {
                      <CButton
                        color="secondary"
                        onClick={() => removeResource(index)}
                      >
                        -
                      </CButton>
                    }
                  </CInputGroup>
                </CCol>
              </CRow>
            ))}
          </div>
        </CCol>

        <CButton
          color="secondary"
          onClick={() => appendResource({ details: "", title: "" })}
        >
          +
        </CButton>
      </CRow>
    </CForm>
  );
}
