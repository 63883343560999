import gql from "graphql-tag";

const PARTICIPANT_PROGRAMS = gql`
  query ParticipantPrograms {
    participantPrograms {
      id
      name
      status
    }
  }
`;

export default PARTICIPANT_PROGRAMS;
