import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Cookies from "js-cookie";

import { CURRENT_USER } from "graphql/queries";

import Loading from "./Loading";

const ROLE_ADMIN = "admin";

export default function AuthorizedRoute() {
  const { data: currentUserQuery, loading, client } = useQuery(CURRENT_USER);

  if (loading) return <Loading />;
  if (!currentUserQuery.currentUser) return <Navigate to="/login" />;

  if (!currentUserQuery.currentUser.roles.includes(ROLE_ADMIN)) {
    Cookies.remove("token");

    client.writeQuery({
      query: CURRENT_USER,
      data: { currentUser: null },
    });

    client.clearStore();

    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
