import "react-app-polyfill/stable";
import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

import App from "./App";
import store from "./store";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PRODUCTION_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = Cookies.get("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePagination(),
          plantClassifications: relayStylePagination(),
          journalEntries: relayStylePagination(["plantId"]),
        },
      },
    },
  }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
