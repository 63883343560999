import React from "react";
import {
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormSwitch,
  CFormLabel,
  CFormText,
} from "@coreui/react";
import { useForm } from "react-hook-form";

export default function ClientVersionForm({ children, clientVersion, onSubmit }) {
  const {
    register,
    handleSubmit: formSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });
  const handleSubmit = (data) => {
    const build = parseInt(data.build, 10);

    onSubmit({
      variables: {
        ...data,
        build,
      },
    });
  };

  return (
    <CForm onSubmit={formSubmit(handleSubmit)}>
      {children}

      <CRow>
        <CCol>
          {clientVersion ? (
            <div className="mb-3">
              <div>
                <strong>ID</strong>
              </div>

              <div>{clientVersion.id}</div>
            </div>
          ) : null}

          <div className="mb-3">
            <CFormLabel htmlFor="url">
              <strong>Version</strong>
            </CFormLabel>

            <CFormInput
              id="version"
              invalid={!!errors.version}
              defaultValue={clientVersion?.version}
              {...register("version")}
            />

            <CFormText>Format: major.minor.patch</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="build">
              <strong>Build</strong>
            </CFormLabel>

            <CFormInput
              id="build"
              type="number"
              invalid={!!errors.build}
              defaultValue={clientVersion?.build}
              {...register("build")}
            />
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="releaseDate">
              <strong>Release date</strong>
            </CFormLabel>

            <CFormInput
              id="releaseDate"
              invalid={!!errors.releaseDate}
              defaultValue={clientVersion?.releaseDate}
              {...register("releaseDate", {
                required: true,
                pattern: /^\d{4}-\d{2}-\d{2}$/,
              })}
            />

            <CFormText>Format: YYYY-MM-DD</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="force">
              <strong>Force update?</strong>
            </CFormLabel>

            <CFormSwitch
              id="force"
              defaultChecked={clientVersion?.force}
              {...register("force")}
            />

            <CFormText>
              Will display update roadblock in app if checked
            </CFormText>
          </div>
        </CCol>
      </CRow>
    </CForm>
  );
}
