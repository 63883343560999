import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilCreditCard,
  cilPlant,
  cilTerminal,
  cilCloudDownload,
  cilDescription,
  cilLibraryBuilding,
  cilTask,
  cilListNumbered,
  cilBarChart,
  cilMoney
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Users",
    to: "/users",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Plant Classifications",
    to: "/plant-classifications",
    icon: <CIcon icon={cilPlant} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Notices",
    to: "/notices",
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Participant Programs",
    to: "/participant-programs",
    icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Client Versions",
    to: "/client-versions",
    icon: <CIcon icon={cilListNumbered} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Actions",
  },
  {
    component: CNavItem,
    name: "Classification Imports",
    to: "/plant-classification-imports",
    icon: <CIcon icon={cilCloudDownload} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Program Requests",
    to: "/program-requests",
    icon: <CIcon icon={cilTask} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "External",
  },
  {
    component: CNavItem,
    name: "Heroku",
    href: "https://dashboard.heroku.com/teams/krado/apps",
    target: "_blank",
    icon: <CIcon icon={cilTerminal} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "RevenueCat",
    href: "https://app.revenuecat.com/overview",
    target: "_blank",
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "MixPanel",
    href: "https://mixpanel.com/project/2919387/view/3446899/app/events",
    target: "_blank",
    icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Stripe",
    href: "https://dashboard.stripe.com/dashboard",
    target: "_blank",
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
  },
];

export default _nav;
