import { lazy } from "react";

const Users = lazy(() => import("views/Users"));
const User = lazy(() => import("views/User"));
const Subscription = lazy(() => import("views/User/Subscription"));
const Plants = lazy(() => import("views/User/Plants"));
const Actions = lazy(() => import("views/User/Actions"));
const Plant = lazy(() => import("views/User/Plant"));
const Links = lazy(() => import("views/User/Links"))
const JournalEntries = lazy(() => import("views/User/Plant/JournalEntries"));
const JournalEntry = lazy(() => import("views/User/Plant/JournalEntry"));
const PlantClassifications = lazy(() => import("views/PlantClassifications"));
const PlantClassification = lazy(() => import("views/PlantClassification"));
const EditPlantClassification = lazy(() =>
  import("views/EditPlantClassification")
);
const PlantClassificationImports = lazy(() =>
  import("views/PlantClassificationImports")
);
const PlantClassificationImport = lazy(() =>
  import("views/PlantClassificationImport")
);
const NewPlantClassificationImport = lazy(() =>
  import("views/NewPlantClassificationImport")
);
const Notices = lazy(() => import("views/Notices"));
const NewNotice = lazy(() => import("views/NewNotice"));
const Notice = lazy(() => import("views/Notice"));
const ParticipantPrograms = lazy(() => import("views/ParticipantPrograms"));
const ParticipantProgram = lazy(() => import("views/ParticipantProgram"));
const NewParticipantProgram = lazy(() => import("views/NewParticipantProgram"));
const ParticipantProgramRequests = lazy(() =>
  import("views/ParticipantProgramRequests")
);
const ClientVersions = lazy(() => import("views/ClientVersions"));
const ClientVersion = lazy(() => import("views/ClientVersion"));
const NewClientVersion = lazy(() => import("views/NewClientVersion"));

const routes = [
  { path: "/",  name: "Dashboard" },
  {
    path: "/users/:id",
    name: "User",
    element: User,
    children: [
      {
        index: true,
        name: "Plants",
        element: Plants,
      },
      {
        path: "plants/:plantId",
        name: "Plant",
        element: Plant,
      },
      {
        path: "plants/:plantId/journal-entries/:journalEntryId",
        name: "JournalEntry",
        element: JournalEntry,
      },
      {
        path: "plants/:plantId/journal-entries",
        name: "JournalEntries",
        element: JournalEntries,
      },
      {
        path: "actions",
        name: "Actions",
        element: Actions,
      },
      {
        path: 'links',
        name: "Links",
        element: Links,
      },
      {
        path: 'subscription',
        name: "Subscription",
        element: Subscription,
      },
    ],
  },
  { path: "/users", name: "Users", element: Users },
  {
    path: "/plant-classifications/:id",
    name: "PlantClassification",
    element: PlantClassification,
  },
  {
    path: "/plant-classifications/:id/edit",
    name: "EditPlantClassification",
    element: EditPlantClassification,
  },
  {
    path: "/plant-classifications",
    name: "PlantClassifications",
    element: PlantClassifications,
  },
  {
    path: "/plant-classification-imports/new",
    name: "NewPlantClassificationImport",
    element: NewPlantClassificationImport,
  },
  {
    path: "/plant-classification-imports/:id",
    name: "PlantClassificationImport",
    element: PlantClassificationImport,
  },
  {
    path: "/plant-classification-imports",
    name: "PlantClassificationImports",
    element: PlantClassificationImports,
  },
  {
    path: "/notices",
    exact: true,
    name: "Notices",
    element: Notices,
  },
  {
    path: "/notices/new",
    exact: true,
    name: "NewNotice",
    element: NewNotice,
  },
  {
    path: "/notices/:id",
    name: "Notice",
    element: Notice,
  },
  {
    path: "/participant-programs",
    name: "ParticipantPrograms",
    element: ParticipantPrograms,
  },
  {
    path: "/participant-programs/new",
    name: "NewParticipantProgram",
    element: NewParticipantProgram,
  },
  {
    path: "/program-requests",
    name: "ParticipantProgramRequests",
    element: ParticipantProgramRequests,
  },
  {
    path: "/participant-programs/:id",
    name: "ParticipantProgram",
    element: ParticipantProgram,
  },
  {
    path: "/client-versions",
    name: "ClientVersions",
    element: ClientVersions,
  },
  {
    path: "/client-versions/:id",
    name: "ClientVersion",
    element: ClientVersion,
  },
  {
    path: "/client-versions/new",
    name: "NewClientVersion",
    element: NewClientVersion,
  },
];

export default routes;
