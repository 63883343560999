import gql from "graphql-tag";

const PLANT_CLASSIFICATION_IMPORT = gql`
  query PlantClassificationImport($id: String!) {
    plantClassificationImport(id: $id) {
      id
      source
      columnMap
      currentState
      plantClassificationImportRows {
        id
        values
        rowNumber
        currentState
        plantClassification {
          id
          name
        }
      }
    }
  }
`;

export default PLANT_CLASSIFICATION_IMPORT;
