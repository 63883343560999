import React from "react";
import {
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormSelect,
  CFormLabel,
  CFormText,
} from "@coreui/react";
import { useForm } from "react-hook-form";

export default function NoticeForm({ children, notice, onSubmit }) {
  const {
    register,
    handleSubmit: formSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });
  const handleSubmit = (data) => {
    const maxViewsCount = parseInt(data.maxViewsCount, 10);
    const priority = parseInt(data.priority, 10);
    const clientBuildMin = parseInt(data.clientBuildMin, 10);
    const clientBuildMax = parseInt(data.clientBuildMax, 10);
    const roles = data.roles.length
      ? data.roles.split(",").map((role) => role.trim())
      : [];

    onSubmit({
      variables: {
        ...data,
        maxViewsCount,
        priority,
        roles,
        clientBuildMin,
        clientBuildMax,
        clientPlatform: data.clientPlatform || null,
      },
    });
  };

  return (
    <CForm onSubmit={formSubmit(handleSubmit)}>
      {children}

      <CRow>
        <CCol>
          {notice ? (
            <div className="mb-3">
              <div>
                <strong>ID</strong>
              </div>

              <div>{notice.id}</div>
            </div>
          ) : null}

          <div className="mb-3">
            <CFormLabel htmlFor="kind">
              <strong>Kind</strong>
            </CFormLabel>

            <CFormSelect
              id="kind"
              invalid={!!errors.kind}
              defaultValue={notice?.kind}
              options={[{ value: "default", label: "default" }]}
              {...register("kind", { required: true })}
            />

            <CFormText>How to display notice</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="displayModule">
              <strong>Display module</strong>
            </CFormLabel>

            <CFormSelect
              id="displayModule"
              invalid={!!errors.displayModule}
              options={[
                { value: "default", label: "default" },
                { value: "discover", label: "discover" },
              ]}
              defaultValue={notice?.displayModule}
              {...register("displayModule", { required: true })}
            />

            <CFormText>Where to display notice</CFormText>
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="url">
              <strong>URL</strong>
            </CFormLabel>

            <CFormInput
              id="url"
              placeholder="URL"
              invalid={!!errors.url}
              defaultValue={notice?.url}
              {...register("url")}
            />

            <CFormText>URL for CTA button</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="cta">
              <strong>CTA label</strong>
            </CFormLabel>

            <CFormInput
              id="cta"
              placeholder="CTA"
              invalid={!!errors.cta}
              defaultValue={notice?.cta}
              {...register("cta")}
            />

            <CFormText>Text for CTA button</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="title">
              <strong>Title</strong>
            </CFormLabel>

            <CFormInput
              id="title"
              placeholder="Title"
              invalid={!!errors.title}
              defaultValue={notice?.title}
              {...register("title")}
            />

            <CFormText>Top heading</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="subtitle">
              <strong>Subtitle</strong>
            </CFormLabel>

            <CFormInput
              id="subtitle"
              placeholder="Subtitle"
              invalid={!!errors.subtitle}
              defaultValue={notice?.subtitle}
              {...register("subtitle")}
            />

            <CFormText>Subheading</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="body">
              <strong>Body*</strong>
            </CFormLabel>

            <CFormTextarea
              id="body"
              placeholder="Body"
              invalid={!!errors.body}
              defaultValue={notice?.body}
              {...register("body", { required: true })}
            />

            <CFormText>Main copy</CFormText>
            <CFormText>\n for newlines</CFormText>
          </div>
        </CCol>

        <CCol>
          <div className="mb-3">
            <CFormLabel htmlFor="startDate">
              <strong>Start date</strong>
            </CFormLabel>

            <CFormInput
              id="startDate"
              invalid={!!errors.startDate}
              defaultValue={notice?.startDate}
              {...register("startDate", {
                required: true,
                pattern: /^\d{4}-\d{2}-\d{2}$/,
              })}
            />

            <CFormText>Date to start displaying</CFormText>
            <CFormText>Format: YYYY-MM-DD</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="endDate">
              <strong>End date</strong>
            </CFormLabel>

            <CFormInput
              id="endDate"
              invalid={!!errors.endDate}
              defaultValue={notice?.endDate}
              {...register("endDate", {
                required: true,
                pattern: /^\d{4}-\d{2}-\d{2}$/,
              })}
            />

            <CFormText>Date to stop displaying</CFormText>
            <CFormText>Format: YYYY-MM-DD</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="maxViewsCount">
              <strong>Max view count</strong>
            </CFormLabel>

            <CFormInput
              id="maxViewsCount"
              type="number"
              invalid={!!errors.maxViewsCount}
              defaultValue={notice?.maxViewsCount}
              {...register("maxViewsCount")}
            />

            <CFormText>Number of times to display to a user</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="priority">
              <strong>Priority</strong>
            </CFormLabel>

            <CFormInput
              id="priority"
              type="number"
              invalid={!!errors.priority}
              defaultValue={notice?.priority}
              {...register("priority")}
            />

            <CFormText>Display order weight</CFormText>
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="clientBuildMin">
              <strong>Client build min</strong>
            </CFormLabel>

            <CFormInput
              id="clientBuildMin"
              placeholder="Client build min"
              invalid={!!errors.clientBuildMin}
              defaultValue={notice?.clientBuildMin}
              {...register("clientBuildMin")}
            />

            <CFormText>
              Minimum app build to display on (Any if blank)
            </CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="clientBuildMax">
              <strong>Client build max</strong>
            </CFormLabel>

            <CFormInput
              id="clientBuildMax"
              placeholder="Client build max"
              invalid={!!errors.clientBuildMax}
              defaultValue={notice?.clientBuildMax}
              {...register("clientBuildMax")}
            />

            <CFormText>
              Maximum app build to display on (Any if blank)
            </CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="clientPlatform">
              <strong>Client platform</strong>
            </CFormLabel>

            <CFormInput
              id="clientPlatform"
              placeholder="Client platform"
              invalid={!!errors.clientPlatform}
              defaultValue={notice?.clientPlatform}
              {...register("clientPlatform")}
            />

            <CFormText>App platform to display on (All if blank)</CFormText>
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="roles">
              <strong>Roles</strong>
            </CFormLabel>

            <CFormInput
              id="roles"
              placeholder="Roles"
              invalid={!!errors.roles}
              defaultValue={notice?.roles?.join(",")}
              {...register("roles")}
            />

            <CFormText>Roles to display to (Any/none if blank)</CFormText>
            <CFormText>Comma-separated list</CFormText>
          </div>
        </CCol>
      </CRow>
    </CForm>
  );
}
