import gql from "graphql-tag";

const JOURNAL_ENTRIES = gql`
  query ClientVersion($id: String!) {
    clientVersion(id: $id) {
      id
      build
      version
      releaseDate
      force
    }
  }
`;

export default JOURNAL_ENTRIES;
