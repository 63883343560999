import gql from "graphql-tag";

const PLANT_CLASSIFICATION = gql`
  query PlantClassification($id: String!) {
    plantClassification(id: $id) {
      id
      name
      family
      genus
      commonNames
      illustrationUrl
      lightRange
      moistureRange
      temperatureRange
      humidityRange
      preferredName
    }
  }
`;

export default PLANT_CLASSIFICATION;
