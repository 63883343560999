import gql from "graphql-tag";

const NOTICES = gql`
  query Notices {
    notices {
      id
      displayModule
      startDate
      endDate
    }
  }
`;

export default NOTICES;
