import gql from "graphql-tag";

const PARTICIPANT_PROGRAM = gql`
  query ParticipantProgram($id: String!) {
    participantProgram(id: $id) {
      id
      name
      details
      incentives
      requiresApproval
      status
      enrollmentSteps
      resources
    }
  }
`;

export default PARTICIPANT_PROGRAM;
