import gql from "graphql-tag";

const NOTICE = gql`
  query Notice($id: String!) {
    notice(id: $id) {
      id
      title
      subtitle
      body
      maxViewsCount
      kind
      displayModule
      url
      startDate
      endDate
      cta
      priority
      clientBuildMin
      clientBuildMax
      clientPlatform
      roles
    }
  }
`;

export default NOTICE;
