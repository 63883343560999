import gql from "graphql-tag";

const PLANTS = gql`
  query Plants($userId: String!) {
    plants(userId: $userId) {
      id
      name
      status
    }
  }
`;

export default PLANTS;
