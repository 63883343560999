import gql from "graphql-tag";

const USERS = gql`
  query Users(
    $filter: UserFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    users(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default USERS;
